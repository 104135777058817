import { useEffect, useRef, useState, useMemo } from "react";
import "./Searchbar.css";
import { SearchOutlined, ContactsOutlined, HomeOutlined, UserAddOutlined } from "@ant-design/icons";
import { useTranslate } from "../Translate";
import { Input } from "antd";
import { useDispatch } from "react-redux";
import AllActions from "../../store/actions";
import { useSelector } from "react-redux";
import { getEnvSettings } from "../../config/config";
import { apiSecure } from "../api";

import { LoadingItem } from "../Loading/Loading";
import { useNavigate } from "react-router-dom";

import { debounce } from 'lodash';
import { AddContactModal } from "../../pages/Project/ProjectInfo/AddContactModal";




const highlightText = (text, highlight) => {
  if (!highlight) return text;
  if (!text) return '';

  // Pakenee kaikki säännöllisen lausekkeen erikoismerkit
  const escapedHighlight = highlight.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  const regex = new RegExp(`(${escapedHighlight})`, 'gi');
  return text.replace(regex, '<span class="font-dm-bold">$1</span>');
};

export const Searchbar = () => {
  const dispatch = useDispatch();
  const { locale } = useTranslate();
  const envConfig = getEnvSettings();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState('');

  const searchInputRef = useRef(null);
  const dropdownRef = useRef(null);

  const { showSearch } = useSelector((state) => state.currentUser);
  const { selectedFactory } = useSelector((state) => state.currentUser.user);

  const [isLoading, setIsLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);


  const [contactsArr, setContactsArr] = useState([]);
  const [projectsArr, setProjectsArr] = useState([]);

  const [filteredContacts, setFilteredContacts] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);

  const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false);


  // Normalisoi hakutermi vain puhelinnumeroita varten
  const normalizePhoneNumber = value => value.replace('+358', '0').replace(/\s|-/g, '');
  const normalizedSearchValue = useMemo(() => searchValue.toLowerCase(), [searchValue]);

  // Yhteinen suodatusfunktio kontakteille ja projekteille
  const filterContacts = contact => {
    const henkiloMatch = contact?.Henkilo?.toLowerCase().includes(normalizedSearchValue);
    const yritysMatch = contact?.Yritys?.toLowerCase().includes(normalizedSearchValue);
    const gsmMatch = contact?.GSM && normalizePhoneNumber(contact.GSM).includes(normalizePhoneNumber(normalizedSearchValue));

    return henkiloMatch || yritysMatch || gsmMatch;
  };

  const filterProjects = project => {
    const tyokohdeMatch = project?.Tyokohde?.toLowerCase().includes(normalizedSearchValue);
    const tyokohdenroMatch = project?.Tyokohdenro?.toString().includes(normalizedSearchValue);
    const henkiloMatch = project?.Henkilo?.toLowerCase().includes(normalizedSearchValue);
    const yritysMatch = project?.Yritys?.toLowerCase().includes(normalizedSearchValue);

    const liitetytHenkilot = project?.LiitetytHenkilot ? JSON.parse(project.LiitetytHenkilot) : [];
    const liitetytHenkilotMatch = liitetytHenkilot.some(liitetty =>
      liitetty?.Henkilo?.toLowerCase().includes(normalizedSearchValue) ||
      liitetty?.Yritys?.toLowerCase().includes(normalizedSearchValue)
    );

    return tyokohdeMatch || tyokohdenroMatch || henkiloMatch || yritysMatch || liitetytHenkilotMatch;
  };


  const debouncedFilter = useMemo(() => debounce(() => {
    if (contactsArr.length <= 0 || projectsArr.length <= 0) return;
    if (!searchValue || searchValue.length <= 2) {
      setFilteredContacts([]);
      setFilteredProjects([]);
      return;
    }

    const nContacts = contactsArr.filter(filterContacts);
    const nProjects = projectsArr.filter(filterProjects);

    setFilteredContacts(nContacts);
    setFilteredProjects(nProjects);
  }, 300), [contactsArr, projectsArr, normalizedSearchValue]);

  useEffect(() => {
    debouncedFilter();

    return () => {
      debouncedFilter.cancel();
    };
  }, [searchValue, contactsArr, projectsArr, debouncedFilter]);








  //Input focus
  useEffect(() => {
    if (!showSearch) {
      setSearchValue('')
    }
  }, [showSearch]);



  //Input focus
  useEffect(() => {
    if (searchInputRef.current && showSearch) {
      searchInputRef.current.focus();
    }
  }, [searchInputRef, showSearch, isLoading]);





  const closeSearch = () => {
    dispatch(AllActions.userActions.updateShowSearch(true));

  }



  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
/*       console.log('CLICK OUTSIDE')
 */      closeSearch()
    } else {
/*       console.log('CLICK INSIDE')
 */    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, []);

  const getSearchData = () => {
    console.log("getSearchData");

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}getSearchData`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        tehdas: selectedFactory
      },
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response);
        setDataLoaded(true)
        setContactsArr(response?.data?.data?.yhteystiedot)
        setProjectsArr(response?.data?.data?.tyokohteet)
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        //setisLoading(false);
      });
  };

  useEffect(() => {
    if (!showSearch || dataLoaded) return
    getSearchData()
  }, [selectedFactory, showSearch]);



  const openContact = (item) => {
    navigate(`/contact/${item.YhteysNRO}`);
    closeSearch()
  }


  const openProject = (item) => {
    /*  console.log('openProject', item) */

    //Päivitetään tehtaan state:
    if (item?.Tehdas) {
      console.log('TEHTAAN PÄIVITYS', item?.Tehdas)
      dispatch(AllActions.userActions.updateFactory(item?.Tehdas));
    }


    navigate(`/project/${item.Tyokohdenro}`);
    closeSearch()
  }



  return (
    <>

      <AddContactModal
        isOpen={isAddContactModalOpen}
        closeModal={() => {
          setIsAddContactModalOpen(false);
        }}
      />

      {showSearch ?

        <>

          {/* Blur overlay */}
          <div className="blur-overlay" />


          <div className="searchbar" ref={dropdownRef}>
            <div className="searchbar-inside">



              <div className="searchbar-inside-row">
                <div className="searchbar-icon-box">

                  <SearchOutlined className="searchbar-icon" />
                </div>

                {!isLoading ?

                  <Input
                    ref={searchInputRef}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder={locale.search}
                    className="searchbar-input font-dm-regular"
                  /> : <div style={{ marginLeft: 10, marginBottom: 10, marginTop: 5 }}>
                    <LoadingItem color="#fff" /> </div>}
              </div>


              {searchValue.length >= 3 && ((filteredContacts.length >= 1) || (filteredProjects.length >= 1)) ?
                <div className="searchbar-results">


                  {filteredContacts.length >= 1 ? <>
                    <div className="searchbar-results-title-row" >
                      <p className="searchbar-results-title font-dm-medium"><ContactsOutlined /> Yhteystiedot</p>
                      <p className="searchbar-results-add clickable" onClick={() => {
                        closeSearch()
                        setIsAddContactModalOpen(true)
                      }}><UserAddOutlined /></p>
                    </div>


                    {filteredContacts.map((itm) => {

                      const hasHenkilo = itm.Henkilo && itm.Henkilo.trim() !== '';
                      const mainText = hasHenkilo ? itm.Henkilo : itm.Yritys; // Jos Henkilo puuttuu, käytetään Yritys päätekstinä
                      const subText = hasHenkilo ? itm.Yritys : ''; // Jos Henkilo löytyy, näytetään Yritys alatekstinä, muuten jätetään tyhjäksi


                      return (
                        <div key={itm.YhteysNRO} className="searchbar-results-contact font-dm-regular clickable" onClick={() => { openContact(itm) }}>
                          <p
                            className="searchbar-results-contact-name"
                            dangerouslySetInnerHTML={{ __html: highlightText(mainText, searchValue) }} // Näytetään päätekstinä joko Henkilo tai Yritys
                          />
                          <div style={{ display: 'flex', flexDirection: 'row', minHeight: '16px' }}>
                            <p dangerouslySetInnerHTML={{ __html: highlightText(subText, searchValue) }} className="searchbar-results-contact-company" />
                            <p dangerouslySetInnerHTML={{ __html: highlightText(itm.GSM, searchValue) }} className="searchbar-results-contact-gms" />
                          </div>
                        </div>
                      )
                    })}
                  </>
                    : <></>}

                  {filteredProjects.length >= 1 ? <>
                    <p className="searchbar-results-title font-dm-medium"><HomeOutlined /> Työkohteet</p>
                    {filteredProjects.map((itm) => (
                      <div key={itm.Tyokohdenro} className="searchbar-results-project font-dm-regular clickable" onClick={() => { openProject(itm) }}>
                        <p
                          dangerouslySetInnerHTML={{ __html: highlightText(`${itm.Tyokohdenro} ${itm.Tyokohde}`, searchValue) }}
                          className="searchbar-results-project-name"
                        />
                        <p className="searchbar-results-project-status" style={{
                          color: itm?.Varikoodi,
                          borderColor: itm?.Varikoodi
                        }}>{itm?.TilaNimi}</p>
                      </div>
                    ))}
                  </> : <></>}


                </div>
                : <></>}
            </div>
          </div>
        </>

        : <></>}
    </>
  );
};
